import Home from '@/views/Home.vue';
import BusinessClubs from '@/views/BusinessClubs.vue';
import Associations from '@/views/Associations.vue';
import SportClubs from '@/views/SportClubs.vue';
import Paramedie from '@/views/Paramedie.vue';
import App from '@/views/App.vue';
import Website from '@/views/Website.vue';
import Members from '@/views/Members.vue';
import Events from '@/views/Events.vue';
import News from '@/views/News.vue';
import Contact from '@/views/Contact.vue';
import RequestDemo from '@/views/RequestDemo.vue';
import WatchDemo from '@/views/WatchDemo.vue';
import Rates from '@/views/Rates.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/businessclubs',
    name: 'businessclubs',
    component: BusinessClubs,
  },
  {
    path: '/verenigingen',
    name: 'verenigingen',
    component: Associations,
  },
  {
    path: '/sportclubs',
    name: 'sportclubs',
    component: SportClubs,
  },
  {
    path: '/paramedie',
    name: 'paramedie',
    component: Paramedie,
  },
  {
    path: '/app',
    name: 'app',
    component: App,
  },
  {
    path: '/website',
    name: 'website',
    component: Website,
  },
  {
    path: '/ledenadministratie',
    name: 'ledenadministratie',
    component: Members,
  },
  {
    path: '/evenementen',
    name: 'evenementen',
    component: Events,
  },
  {
    path: '/nieuws',
    name: 'nieuws',
    component: News,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/demo-aanvragen',
    name: 'demo-aanvragen',
    component: RequestDemo,
  },
  {
    path: '/bekijk-demo',
    name: 'bekijk-demo',
    component: WatchDemo,
  },
  {
    path: '/tarieven',
    name: 'tarieven',
    component: Rates,
  },
];
