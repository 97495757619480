<!-- eslint-disable max-len, vue/max-len -->
<template>
  <section
    id="home"
    class="pt-100 md:pt-150"
  >
    <div class="absolute top-0 right-0 mt-100 lg:mt-150 w-full md:w-1/2 max-w-4xl">
      <div class="w-full pt-[100%] bg-gradient-to-r from-primary-400 to-primary-500 rounded-full ml-[20%]" />
      <img
        src="/img/hero-website.webp"
        alt="Website software Clubee"
        title="Website software Clubee"
        class="absolute top-0 right-0 mt-50 pl-25 md:-ml-50 md:pl-0"
      >
    </div>
    <div class="container relative mt-[110%] md:mt-0 xl:mt-50 2xl:mt-100">
      <div class="w-full md:w-1/2 max-w-4xl">
        <h1 class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-25">
          Makkelijk een <span class="marker marker-lg">een website</span> maken voor jouw club of vereniging
        </h1>
        <p class="text-gray-500 text-lg lg:text-xl mt-25 sm:max-w-xs lg:max-w-xl">
          Makkelijk een website maken doe je met Clubee: de ideale oplossing voor clubs en verenigingen die een eigen professionele website willen maken. Binnen een dag zetten wij de basis van jouw website klaar. Vervolgens kies je zelf je huisstijl en vul je de website met je eigen content. Plan evenementen en laat leden zich opgeven. En deel je agenda, nieuws en foto's. Probeer Clubee en vraag direct een gratis demo aan
        </p>

        <div class="flex flex-col items-start">
          <router-link
            to="/demo-aanvragen"
            class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Demo aanvragen
          </router-link>

          <router-link
            :to="{
              path: '/website',
              hash: '#club',
            }"
            class="inline-flex items-center mt-25 font-semibold group"
          >
            <span class="h-50 w-50 mr-10 bg-black bg-opacity-5 group-hover:bg-opacity-10 transition rounded-full flex items-center justify-center">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                d="M13.707 8.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L6 12.586V1a1 1 0 0 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 0z"
                fill="#000"
                fill-rule="evenodd"
              /></svg>
            </span>
            Ontdek Clubee
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section
    id="club"
    class="pt-100 lg:pt-150 md:mt-100 2xl:mt-150"
  >
    <div class="container">
      <div class="rounded-3xl bg-gray-100 p-25 md:p-50 xl:p-100">
        <div class="flex flex-wrap justify-between">
          <div class="w-full lg:w-2/5 lg:pr-20">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              Alles wat je wilt in je eigen <span class="marker">website</span>
            </h2>
            <p class="text-gray-500 mt-15">
              Met Clubee laat je in mum van tijd de basis voor je eigen website maken. Je kiest vervolgens zelf de huisstijl en plaatst makkelijk content op je eigen website zoals nieuws, evenementen en meer. Zo kan je zelf een professionele website maken, zonder ingewikkelde tools. Met Clubee beschik je direct over de volgende features.
            </p>
            <router-link
              to="/demo-aanvragen"
              class="inline-flex mt-40 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Gratis demo aanvragen
            </router-link>
          </div>
          <div class="w-full lg:w-3/5">
            <div class="flex flex-wrap items-start">
              <div class="w-full md:w-1/2 lg:pl-25">
                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-50">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Nieuws
                  </h4>
                  <p>Deel het laatste nieuws met je leden door blogs of nieuwsartikelen.</p>
                </div>

                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-25">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Evenementen
                  </h4>
                  <p>Deel de agenda met aankomende evenementen. Laat leden zich opgeven of afmelden zodat je precies weet hoeveel mensen er komen</p>
                </div>
              </div>

              <div class="w-full md:w-1/2 md:pl-25">
                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-25 md:mt-0">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Ledenomgeving
                  </h4>
                  <p>Je leden loggen in op jouw omgeving. Ze kunnen hun gegevens zelf aanpassen en zich opgeven voor aankomende events.</p>
                </div>

                <div class="bg-white text-gray-500 text-sm rounded-2xl p-25 mt-25">
                  <h4 class="text-lg font-medium mb-5 text-black">
                    Foto's
                  </h4>
                  <p>Deel foto's met iedereen of alleen met je leden. Bijvoorbeeld van een nieuwjaarsborrel of een afgelopen sportevenement.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="grid md:grid-cols-12 gap-50">
        <div class="col-span-full md:col-span-8">
          <blockquote class="relative pl-40 before:absolute before:inset-y-0 before:left-0 before:bg-gradient-to-b before:from-primary-400 before:to-primary-500 before:w-5">
            <span class="block text-2xl lg:text-3xl 2xl:text-4xl/tight font-bold italic">
              “Clubee biedt een gebruiksvriendelijk platform voor clubbeheer, waardoor taken als evenement- en ledenbeheer eenvoudig en efficiënt worden afgehandeld.”
            </span>
            <cite class="block mt-25 not-italic text-gray-500 text-lg xl:text-xl 2xl:text-2xl">
              &mdash; Raymond Keemers, BGT Tubbergen
            </cite>
          </blockquote>
        </div>
        <figure class="col-span-full md:col-span-4 md:col-start-9">
          <img
            src="/img/raymond.webp"
            alt="Raymond Keemers, BGT Tubbergen"
            title="Raymond Keemers, BGT Tubbergen"
            class="rounded-3xl"
          >
        </figure>
      </div>
    </div>
  </section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="grid md:grid-cols-12 gap-y-50 gap-x-12">
        <div class="col-span-full md:col-span-10 lg:col-span-6">
          <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
            <span class="marker">Makkelijk</span>
            een website maken voor jouw club
          </h2>
          <p class="text-lg text-gray-500 mt-20">
            Op zoek naar een website voor je businessclub, vereniging of sportclub? Dan is Clubee de oplossing. Bij Clubee krijg je een eigen app en website die past bij jouw organisatie en leden. Je deelt gemakkelijk de agenda, evenementen, en nieuws. Ook kunnen leden foto's bekijken in een afgeschermde omgeving.
          </p>
        </div>

        <div class="col-span-full md:row-start-2 md:col-span-4">
          <div class="bg-gray-100 h-full rounded-3xl p-10 overflow-hidden flex flex-col justify-between">
            <img
              class="ml-auto -mr-10"
              src="/img/website-business.webp"
              alt="businessclub website"
              title="businessclub website"
            >
            <router-link
              :to="{
                path: '/businessclubs',
              }"
              class="relative block bg-white rounded-2xl p-25 lg:p-30 group hover:no-underline hover:bg-gray-200 transition"
            >
              <h5 class="text-xl font-bold mb-10 group-hover:underline">
                Businessclub of netwerkclub website
              </h5>
              <div class="flex items-center gap-8 text-gray-500">
                <span class="inline-flex items-center justify-center rounded-full size-25 shrink-0 bg-primary-400">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.42977 0.988701C4.62167 0.796802 4.93283 0.796802 5.12473 0.988701L8.07324 3.93721C8.26513 4.1291 8.26513 4.44026 8.07324 4.63217L5.12473 7.58068C4.93283 7.77259 4.62167 7.77259 4.42977 7.58068C4.23785 7.38876 4.23785 7.07762 4.42977 6.88571L6.53937 4.7761L0.84591 4.7761C0.574509 4.7761 0.354492 4.55609 0.354492 4.28469C0.354492 4.01328 0.574509 3.79327 0.845909 3.79327L6.53937 3.79327L4.42977 1.68366C4.23785 1.49176 4.23785 1.1806 4.42977 0.988701Z"
                      fill="#04283D"
                    />
                  </svg>
                </span>
                Bekijk Clubee voor businessclubs
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-span-full h-full md:row-start-2 md:col-span-4">
          <div class="bg-gray-100 rounded-3xl h-full p-10 overflow-hidden flex flex-col justify-between">
            <img
              class="mr-auto -ml-10"
              src="/img/website-vereniging.webp"
              alt="vereniging website"
              title="vereniging website"
            >
            <router-link
              :to="{
                path: '/verenigingen',
              }"
              class="relative block bg-white -mt-20 rounded-2xl p-25 lg:p-30 group hover:no-underline hover:bg-gray-200 transition"
            >
              <h5 class="text-xl font-bold mb-10 group-hover:underline">
                Websites voor verenigingen
              </h5>
              <div class="flex items-center gap-8 text-gray-500">
                <span class="inline-flex items-center justify-center rounded-full size-25 shrink-0 bg-primary-400">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.42977 0.988701C4.62167 0.796802 4.93283 0.796802 5.12473 0.988701L8.07324 3.93721C8.26513 4.1291 8.26513 4.44026 8.07324 4.63217L5.12473 7.58068C4.93283 7.77259 4.62167 7.77259 4.42977 7.58068C4.23785 7.38876 4.23785 7.07762 4.42977 6.88571L6.53937 4.7761L0.84591 4.7761C0.574509 4.7761 0.354492 4.55609 0.354492 4.28469C0.354492 4.01328 0.574509 3.79327 0.845909 3.79327L6.53937 3.79327L4.42977 1.68366C4.23785 1.49176 4.23785 1.1806 4.42977 0.988701Z"
                      fill="#04283D"
                    />
                  </svg>
                </span>
                Bekijk Clubee voor verenigingen
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-span-full h-full md:row-start-2 md:col-span-4">
          <div class="bg-gray-100 rounded-3xl p-10 overflow-hidden h-full flex flex-col justify-between">
            <img
              class="-mr-10 ml-auto"
              src="/img/website-sportclub.webp"
              alt="sportclub website"
              title="sportclub website"
            >
            <router-link
              :to="{
                path: '/sportclubs',
              }"
              class="relative block bg-white -mt-20 rounded-2xl p-25 lg:p-30 group hover:no-underline hover:bg-gray-200 transition"
            >
              <h5 class="text-xl font-bold mb-10 group-hover:underline">
                Sportclub websites
              </h5>
              <div class="flex items-center gap-8 text-gray-500">
                <span class="inline-flex items-center justify-center rounded-full size-25 shrink-0 bg-primary-400">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.42977 0.988701C4.62167 0.796802 4.93283 0.796802 5.12473 0.988701L8.07324 3.93721C8.26513 4.1291 8.26513 4.44026 8.07324 4.63217L5.12473 7.58068C4.93283 7.77259 4.62167 7.77259 4.42977 7.58068C4.23785 7.38876 4.23785 7.07762 4.42977 6.88571L6.53937 4.7761L0.84591 4.7761C0.574509 4.7761 0.354492 4.55609 0.354492 4.28469C0.354492 4.01328 0.574509 3.79327 0.845909 3.79327L6.53937 3.79327L4.42977 1.68366C4.23785 1.49176 4.23785 1.1806 4.42977 0.988701Z"
                      fill="#04283D"
                    />
                  </svg>
                </span>
                Bekijk Clubee voor sportclubs
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <contact-form-section
    id="contact"
    name="Website"
    club-label="Naam club"
    text="Met de gratis demo ervaar je zelf hoe een eigen website maken voor jouw club of vereniging werkt. Wij maken binnen no time een professionele website voor je aan, en we doorlopen samen alle mogelijkheden van Clubee. Ontdek Clubee en vraag direct een gratis demo aan."
  >
    <template #title>
      <h2 class="text-3xl xl:text-4xl font-bold mb-10">
        Vraag nu een <span class="marker marker-white">gratis demo</span> aan
      </h2>
    </template>
  </contact-form-section>

  <section class="pt-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto grid md:grid-cols-12 gap-20 items-center md:gap-50">
        <div class="col-span-6 lg:col-span-5">
          <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold mb-25">
            Bereken nu de kosten voor jouw website
          </h2>
          <p class="xl:text-xl text-gray-500 mb-25">
            Met Clubee laat je makkelijk en supersnel jouw website ontwikkelen, en dat voor een goedkoop tarief. Wil je weten wat de kosten voor jou website inclusief app en ledenadministratie zijn? Bereken het zelf met onze handige tool, of neem contact op voor meer info
          </p>
          <router-link
            :to="'/tarieven'"
            class="inline-flex font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
          >
            Bereken direct
          </router-link>
        </div>
        <div class="col-span-6 lg:col-span-7">
          <img
            src="/img/tarieven.png"
            alt="kosten verenigingen softwaren"
            title="kosten verenigingen software"
          >
        </div>
      </div>
    </div>
  </section>

  <section class="py-100 lg:pt-150">
    <div class="container">
      <div class="w-full xl:w-4/5 mx-auto rounded-3xl bg-gray-100 p-25 md:p-50 xl:p-100">
        <div class="flex flex-wrap gap-y-20 justify-between">
          <div class="w-full lg:w-3/5 lg:pr-50">
            <h2 class="text-3xl md:text-4xl xl:text-5xl font-bold">
              Heb jij een <span class="marker">vraag?</span><br>
              Wij helpen je graag!
            </h2>
            <p class="text-gray-500 mt-15 lg:text-xl">
              Wil je meer weten over onze oplossingen of heb je een andere vraag? Wij helpen je graag! Stuur ons een mail of bel direct met ons voor meer informatie.
            </p>
            <div class="flex flex-col items-start mt-40 lg:flex-row gap-x-25 gap-y-8">
              <a
                href="tel:+31854019360"
                class="inline-flex items-center gap-x-8 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
              >
                <span class="inline-flex items-center justify-center bg-white/80 rounded-full size-30">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.17947 9.93584C6.60157 12.4573 9.32291 13.5171 10.9072 13.9476C11.6663 14.1541 12.4524 13.7341 12.7543 12.9795L13.6345 10.7765C13.8746 10.1759 13.6137 9.48504 13.0441 9.21624L11.2508 8.36853C10.7034 8.10953 10.0585 8.23133 9.63357 8.67443L8.82867 9.51234C8.82867 9.51234 7.72185 9.20784 6.30032 7.72803C4.8788 6.24822 4.58629 5.09601 4.58629 5.09601L5.39119 4.25811C5.81617 3.81571 5.93318 3.1444 5.68505 2.5746L4.87073 0.706988C4.61252 0.114784 3.94883 -0.157517 3.37188 0.093084L1.25574 1.00939C0.530854 1.32299 0.127394 2.1413 0.325762 2.9323C0.739308 4.58151 1.75737 7.41443 4.17947 9.93584Z"
                      fill="black"
                    />
                  </svg>
                </span>
                085 - 401 9360
              </a>
              <a
                href="mailto:info@clubee.nl"
                class="inline-flex items-center gap-x-8 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full text-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
              >
                <span class="inline-flex items-center justify-center bg-white rounded-full size-30">
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.6 0C0.2682 0 0 0.28 0 0.625V0.938721L6 4.88525L12 0.938721V0.625C12 0.28 11.7318 0 11.4 0H0.6ZM0 2.41699V9.375C0 9.72 0.2682 10 0.6 10H11.4C11.7318 10 12 9.72 12 9.375V2.41699L6.31992 6.15356C6.22212 6.21794 6.1116 6.25 6 6.25C5.8884 6.25 5.77788 6.21794 5.68008 6.15356L0 2.41699Z"
                      fill="black"
                    />
                  </svg>
                </span>
                info@clubee.nl
              </a>
            </div>

            <div class="mt-25 lg:mt-60">
              <h6 class="text-lg xl:text-xl/tight font-semibold mb-20">
                Support
              </h6>
              <p class="text-gray-600 text-lg">
                Voor ondersteuning van je Clubee website kun je contact opnemen via
                <a
                  href="mailto:support@clubee.nl"
                  class="text-black underline hover:no-underline"
                >
                  support@clubee.nl
                </a>
                of
                <a
                  href="tel:0851304667"
                  class="text-black underline hover:no-underline"
                >
                  085-130 4667
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-2/5 lg:pl-75">
            <img
              src="/img/contact.jpg"
              alt="Clubee"
              class="rounded-2xl w-full mb-20 lg:mb-50"
            >
            <ul class="leading-normal text-black">
              <li class="flex items-center space-x-5 mt-20">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  d="M23.62 0C24.382 0 25 .618 25 1.38v22.24A1.38 1.38 0 0 1 23.62 25H1.38A1.38 1.38 0 0 1 0 23.62V1.38C0 .618.618 0 1.38 0h22.24zm-6.517 9.596c-1.92 0-3.122 1.04-3.344 1.768V9.698H9.996c.012.226.018 1.026.02 2.093v.51l.001.268v1.424c-.004 2.46-.016 5.209-.02 6.056l-.001.166v.098h3.763v-6.06c.002-.206.017-.4.082-.552.258-.638.814-1.3 1.814-1.3 1.306 0 1.9.98 1.9 2.419v5.492h3.8V14.41c0-3.287-1.871-4.814-4.252-4.814zm-9.153.102H4.6v10.614h3.35V9.698zm-1.737-5.01c-1.237 0-2.046.794-2.046 1.842 0 1.029.786 1.84 1.999 1.84h.024c1.259 0 2.043-.814 2.043-1.843-.024-1.047-.784-1.84-2.02-1.84z"
                  fill="#007EBB"
                /></svg>
                <span>Volg ons op</span>
                <a
                  href="https://www.linkedin.com/company/clubee-nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-black underline hover:no-underline"
                >
                  LinkedIn
                </a>
              </li>
              <li class="flex items-center space-x-5 mt-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 132 132"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <linearGradient id="b">
                      <stop
                        offset="0"
                        stop-color="#3771c8"
                      />
                      <stop
                        stop-color="#3771c8"
                        offset=".128"
                      />
                      <stop
                        offset="1"
                        stop-color="#60f"
                        stop-opacity="0"
                      />
                    </linearGradient>
                    <linearGradient id="a">
                      <stop
                        offset="0"
                        stop-color="#fd5"
                      />
                      <stop
                        offset=".1"
                        stop-color="#fd5"
                      />
                      <stop
                        offset=".5"
                        stop-color="#ff543e"
                      />
                      <stop
                        offset="1"
                        stop-color="#c837ab"
                      />
                    </linearGradient>
                    <radialGradient
                      id="c"
                      cx="158.429"
                      cy="578.088"
                      r="65"
                      xlink:href="#a"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                      fx="158.429"
                      fy="578.088"
                    />
                    <radialGradient
                      id="d"
                      cx="147.694"
                      cy="473.455"
                      r="65"
                      xlink:href="#b"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                      fx="147.694"
                      fy="473.455"
                    />
                  </defs>
                  <path
                    fill="url(#c)"
                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                    transform="translate(1.004 1)"
                  />
                  <path
                    fill="url(#d)"
                    d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                    transform="translate(1.004 1)"
                  />
                  <path
                    fill="#fff"
                    d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
                  />
                </svg>
                <span>Volg ons op</span>
                <a
                  href="https://www.instagram.com/clubee_nl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-black underline hover:no-underline"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import ContactFormSection from '@/components/ContactFormSection.vue';

export default defineComponent({
  components: {
    ContactFormSection,
  },
});
</script>
